import { Icon } from "@iconify/react";
import { clsx } from "clsx";
import {
  DetailedHTMLProps,
  InputHTMLAttributes,
  useEffect,
  useState,
} from "react";

type InputPropType = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  containerClassName?: string;
  colored?: boolean;
  error?: string | boolean;
  fieldSize?: "large" | "small";
};

const Input = ({
  containerClassName,
  error,
  fieldSize,
  value: propsValue,
  ...props
}: InputPropType) => {
  const [showPassword, setShowPassword] = useState(false);
  const [value, setValue] = useState(propsValue || "");

  useEffect(() => {
    setValue(propsValue || "");
  }, [propsValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (props.type === "number") {
      const numericValue = Number(newValue);
      if (newValue === "" || (!isNaN(numericValue) && numericValue >= 0)) {
        setValue(newValue);
        props.onChange?.(e);
      }
    } else {
      setValue(newValue);
      props.onChange?.(e);
    }
  };

  return (
    <div
      className={clsx(
        "w-full text-sm relative rounded-full flex items-center mb-6",
        containerClassName
      )}
    >
      <input
        {...props}
        value={value}
        onChange={handleChange}
        className={clsx(
          "w-full border focus:!border-gray-400 font-medium text-gray-900 outline-none bg-transparent rounded-[inherit]",
          error ? "!border-accent-pink" : "!border-gray-300",
          props.type === "date" && "leading-[1.2875]",
          !fieldSize && props.type === "password"
            ? "pl-4 py-2.5 pr-9"
            : fieldSize === "large" && props.type === "password"
            ? "pl-4 py-3 pr-10"
            : fieldSize === "small" && props.type === "password"
            ? "pl-3 py-2 pr-8"
            : fieldSize === "large"
            ? "px-4 py-3"
            : fieldSize === "small"
            ? "px-3 py-2"
            : "px-4 py-2.5",
          props.className,
          // props.type === "color" && "p-0 h-8 w-8" // Adjust size for color input
        )}
        style={
          props.type === "color"
            ? { backgroundColor: value as string } // Set background color for color input
            : undefined
        }
        type={showPassword ? "text" : props.type}
      />

      {props.type === "password" && (
        <button
          type="button"
          className={clsx(
            "shrink-0 absolute p-0.5",
            fieldSize === "small" ? "right-3" : "right-4"
          )}
          onClick={() => setShowPassword(!showPassword)}
        >
          <Icon icon={`heroicons:eye${showPassword ? "" : "-slash"}`} />
        </button>
      )}

      {error && typeof error === "string" && (
        <p className="absolute left-0 text-xs leading-none translate-y-1 text-accent-pink top-full w-full truncate">
          {error}
        </p>
      )}
    </div>
  );
};

export default Input;
