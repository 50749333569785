import { Icon } from "@iconify/react";
import { createId } from "@paralleldrive/cuid2";
import { useClickOutside } from "@reactuses/core";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface IDropdown {
  options: { name: string; value: string; icon?: ReactNode }[];
  onChange: (value: string) => void;
  showSearch?: boolean;
}

const Dropdown: FC<IDropdown & { initialOption?: string }> = ({
  initialOption,
  options,
  onChange,
  showSearch,
}) => {
  const [showDropDown, setshowDropDown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState<IDropdown["options"][0]>(
    options[0]
  );

  useClickOutside(dropdownRef, () => setshowDropDown(false));

  const [searchValue, setsearchValue] = useState("");

  useEffect(() => {
    if (!initialOption) return;
    const option = options.find(
      (opt) => opt.value === initialOption || opt.name === initialOption
    );
    if (!option) return;
    setSelectedOption(option);
    onChange(option.value);
  }, []);

  return (
    <div ref={dropdownRef} className="relative">
      <button
        type="button"
        onClick={() => setshowDropDown(!showDropDown)}
        className={clsx(
          "flex items-center gap-1 px-3 py-2  text-dark rounded-full border bg-white w-full"
        )}
      >
        <div className="w-full flex items-center gap-2 [text-align:initial] text-sm font-medium">
          {
            <>
              {selectedOption?.icon && (
                <i className="w-4 md:w-[1.125rem] shrink-0 empty:hidden">
                  {selectedOption.icon}
                </i>
              )}
              <span className="max-w-full truncate">
                {t(selectedOption?.name)}
              </span>
            </>
          }
        </div>
        <Icon
          icon="tabler:chevron-down"
          fontSize={16}
          className={clsx("transition will-change-transform shrink-0", {
            "rotate-180": showDropDown,
          })}
        />
      </button>
      <AnimatePresence>
        {showDropDown && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute left-0 right-0 bg-white top-full translate-y-0.5 drop-shadow py-1.5 px-1 rounded-xl min-w-fit z-50 divide-y flex flex-col items-stretch gap-0.5"
          >
            {options.length > 1 && showSearch && (
              <div className="flex items-center relative">
                <Icon
                  icon="tabler:search"
                  className="absolute left-3 text-darker"
                />
                <input
                  type="text"
                  value={searchValue}
                  onChange={({ target }) => setsearchValue(target.value)}
                  className="w-full py-1.5 pr-3 outline-none pl-8 rounded-md border-b"
                />
              </div>
            )}
            {options
              .map((option) => ({ id: createId(), ...option }))
              .filter((option) => {
                const regexp = new RegExp(searchValue, "gui");
                return regexp.test(option.value || option.name);
              })
              .map((option) => (
                <div
                  key={`dropdown-option-${option.name}`}
                  className="relative"
                >
                  <input
                    type="radio"
                    name="marketplacechange"
                    id={option.id}
                    value={option.value}
                    checked={selectedOption.value === option.value}
                    className="sr-only peer"
                    onChange={({ target }) => {
                      setSelectedOption(option);
                      onChange(target.value);
                      setshowDropDown(false);
                    }}
                  />
                  <label
                    role="button"
                    htmlFor={option.id}
                    className="flex items-center gap-3 py-1.5 px-3 rounded-md border-gray-100 hover:bg-light-tint/5 peer-checked:bg-light-tint/10 text-sm"
                  >
                    {option.icon && (
                      <i className="w-4 md:w-[1.125rem] shrink-0 empty:hidden">
                        {option.icon}
                      </i>
                    )}
                    <span className="whitespace-nowrap overflow-hidden text-nowrap max-w-full text-ellipsis">{option.name}</span>
                  </label>
                </div>
              ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Dropdown;
